@import '@/styles/breakpoints.module';

.masonry2x2Block {
  --masonry-2x2-gap: 16px;
  --masonry-icon-size: 80px;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto auto auto auto;
  grid-gap: var(--masonry-2x2-gap);

  @include tablet {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    padding-bottom: var(--masonry-2x2-gap);

    > *:nth-child(2n - 1) {
      margin-top: var(--masonry-2x2-gap);
      margin-bottom: calc(-1 * var(--masonry-2x2-gap));
    }
  }

  @include desktop {
    --masonry-2x2-gap: 30px;
  }
}

.card {
  height: 100%;
  border-radius: var(--palette-border-radius-m);
  border: 1px solid var(--color-btn-social-filled-hover-bg);
  background-color: var(--palette-gray-700);
  padding: 16px;
  text-align: start;
  @include tablet {
    padding: 24px;
  }

  &.cardHoverable {
    &:hover {
      background-color: var(--palette-primary-700);

      & * {
        color: var(--palette-white);
      }
    }
  }

  .imgWrapper {
    width: var(--masonry-icon-size);
    height: var(--masonry-icon-size);
    margin-bottom: 24px;
    @include tablet {
      margin-bottom: 32px;
    }
    @include desktop {
      margin-bottom: 44px;
    }
  }
}

.cardTitle {
  padding-bottom: 16px;
  border-bottom: 1px solid var(--palette-gray-500);
  margin-bottom: 16px;
  @include desktop {
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
}
