@import '@/styles/breakpoints.module';

.cardsGrid {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 1fr;
  gap: 16px;
  padding: 2px;

  @include tablet {
    grid-template-columns: 1fr 1fr;
  }

  @include desktop {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: var(--cards-grid-gap);
  }
}

.ghost {
  position: absolute;
  visibility: hidden;
  top: -99999px;
  inset-inline-start: -99999px;
}

.panelsContainer {
  overflow: hidden;
}
