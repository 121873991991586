.boldText {
  font-weight: var(--palette-font-weight-bold);
}

.list {
  list-style-type: disc;
  list-style: inside;

  li:nth-child(odd) {
    margin-inline-start: 5px;
  }

  li:nth-child(even) {
    margin-inline-start: 35px;
  }
}
